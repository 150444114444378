import React, { useEffect, useState } from "react"
import { graphql, Link, navigate } from "gatsby"
import Img from "gatsby-image"
import {
  Flex,
  Box,
  Badge,
  PseudoBox,
  Button,
  FormControl,
  FormLabel,
  Select,
} from "@chakra-ui/core"
import { groupBy, startCase } from "lodash"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { H1, H3 } from "../components/Typography"
import firebase from "gatsby-plugin-firebase"

export function formatMoney(
  amount,
  decimalCount = 2,
  decimal = ".",
  thousands = ","
) {
  try {
    decimalCount = Math.abs(decimalCount)
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount

    const negativeSign = amount < 0 ? "-" : ""

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString()
    let j = i.length > 3 ? i.length % 3 : 0

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    )
  } catch (e) {
    console.log(e)
  }
}

export function ClasifiedCard({ classified, ...rest }) {
  return (
    <PseudoBox
      _hover={{
        boxShadow: "xl",
      }}
    >
      <Link to={`/classifieds/${classified.fields.slug}`}>
        <Flex
          borderWidth="1px"
          boxShadow="sm"
          rounded="lg"
          overflow="hidden"
          flex={1}
          w="250px"
          minH="315px"
          direction="column"
          {...rest}
        >
          {classified.featuredImageUrl && classified.featuredImageUrl.childImageSharp?.fixed &&  (
            <Img
              fixed={classified.featuredImageUrl.childImageSharp.fixed}
              alt="featuredImage"
              width="250x"
              height="140px"
            />
          )}
          <Flex paddingX={4} paddingY={2} flex={1} direction="column">
            <Box d="flex" alignItems="baseline">
              <Badge rounded="full" px="2" variantColor="blue">
                {classified.classifiedCategories?.name || null}
              </Badge>
              <Flex direction="column">
                <Box
                  color="gray.500"
                  fontWeight="semibold"
                  letterSpacing="wide"
                  fontSize="xs"
                  textTransform="uppercase"
                  ml="2"
                >
                  {classified.fields.date}
                </Box>
                <Box
                  color="gray.500"
                  fontWeight="semibold"
                  letterSpacing="wide"
                  fontSize="xs"
                  textTransform="uppercase"
                  ml="2"
                >
                  &bull; {classified.location}
                </Box>
              </Flex>
            </Box>
            <Flex
              direction="column"
              justify="space-between"
              align="center"
              flex={1}
            >
              <Flex
                direction="column"
                justify="center"
                align="flex-start"
                width="100%"
                mt={2}
              >
                <Box
                  fontWeight="semibold"
                  as="h4"
                  lineHeight="tight"
                  textAlign="center"
                  mt={3}
                >
                  R {formatMoney(classified.price)}
                </Box>
                <Box
                  fontWeight="normal"
                  as="h4"
                  lineHeight="tight"
                  textAlign="left"
                >
                  {classified.title}
                </Box>
              </Flex>
              <Button variant="ghost" variantColor="Gray" color="Gray" w="100%">
                View
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Link>
    </PseudoBox>
  )
}

export default function ClassifiedsListTemplate({
  data,
  pageContext,
  ...rest
}) {
  const [selectedCategory, setSelectedCategory] = useState("all")
  const classifieds = data.allClassified?.edges?.map(edge => edge.node) || []
  const { currentPage, numPages } = pageContext || {}
  const hasMore = numPages > currentPage
  const hasLess = currentPage > 1

  const cgroups = groupBy(classifieds, "classifiedCategories.name")
  const categories = Object.keys(cgroups) || []

  useEffect(() => {
    firebase.analytics().setCurrentScreen(`classifieds_page_${currentPage}`)
  }, [])

  return (
    <Layout>
      <SEO
        title="Classified Ads"
        description="Laudium Today classifieds makes selling and buying fun, used furniture, cars, cellphones and more. It's a one-stop shop to reach out into your community and save/make money fast!"
      />
      <H1 textAlign="center">Classified Ads</H1>
      <Link to={`/disclaimer`}>
      <H3 my={5} textDecoration='underline' fontWeight='normal' textAlign="center">Disclaimer</H3>
      </Link>
      <Flex
        width="100%"
        background="linear-gradient(100deg, #64b9ed,#1b95e0);"
        borderRadius={4}
        p={4}
        mt={4}
        color="white"
      >
        <FormControl width="100%">
          <FormLabel htmlFor="category">Category:</FormLabel>
          <Select
            placeholder="Select category"
            backgroundColor="white"
            id="category"
            name="category"
            value={selectedCategory}
            color="black"
            onChange={e => setSelectedCategory(e.target.value)}
          >
            <option key="alll" value="all">
              All
            </option>
            {categories.map(category => (
              <option key={category} value={category}>
                {startCase(category)}
              </option>
            ))}
          </Select>
        </FormControl>
      </Flex>
      <Link to="http://onelink.to/5zdue7">
        <Button marginY={4} p={8} width="100%" variantColor="blue">
          Download the app to post a classified Ad
        </Button>
      </Link>
      <Flex
        direction={["column", "row"]}
        justify={["center", "space-between"]}
        align={["center", "flex-start"]}
        wrap="wrap"
        flex={1}
      >
        {classifieds
          .filter(cat =>
            selectedCategory.length > 0 && selectedCategory !== "all"
              ? cat.classifiedCategories.name === selectedCategory
              : true
          )
          .map(classifiedAd => (
            <ClasifiedCard
              classified={classifiedAd}
              key={classifiedAd.id}
              mt={8}
            />
          ))}
      </Flex>
      <Flex justify="center" align="center" w="100%" mt={8}>
        <Button
          isDisabled={!hasLess}
          variantColor="black"
          variant="outline"
          color="black"
          onClick={() => {
            if (currentPage > 2) {
              navigate(`/classifieds/${currentPage - 1}`)
            } else navigate(`/classifieds`)
          }}
        >
          Previous
        </Button>
        <Flex marginX={4}>
          Page {currentPage} of {numPages}
        </Flex>
        <Button
          isDisabled={!hasMore}
          variantColor="black"
          variant="outline"
          color="black"
          onClick={() => {
            if (currentPage < numPages) {
              navigate(`/classifieds/${currentPage + 1}`)
            }
          }}
        >
          Next
        </Button>
      </Flex>
    </Layout>
  )
}

export const pageQuery = graphql`
  query classifiedList($skip: Int!, $limit: Int!) {
    allClassified(
      skip: $skip
      limit: $limit
      sort: { fields: fields___date, order: DESC }
    ) {
      edges {
        node {
          id
          title
          classifiedCategories {
            name
          }
          description
          price
          location
          featuredImageUrl {
            childImageSharp {
              fixed(width: 250, height: 140) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          fields {
            slug
            date(formatString: "DD MMMM YYYY")
          }
        }
      }
    }
  }
`
